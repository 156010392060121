export default function ReasonIcon({icon}) {
    switch (icon) {
        case "cash":
            
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><g fill="none" stroke="#2e2209" strokeLinecap="round" strokeLinejoin="round"><path d="M7 10.02v1.01m0-6.02v.94m0 7.54c3.5 0 6-1.24 6-4c0-3-1.5-5-4.5-6.5l1.18-1.52a.66.66 0 0 0-.56-1H4.88a.66.66 0 0 0-.56 1L5.5 3C2.5 4.51 1 6.51 1 9.51c0 2.74 2.5 3.98 6 3.98Z"/><path d="M6 9.56A1.24 1.24 0 0 0 7 10a1.12 1.12 0 0 0 1.19-1A1.12 1.12 0 0 0 7 8a1.12 1.12 0 0 1-1.19-1A1.11 1.11 0 0 1 7 6a1.26 1.26 0 0 1 1 .4"/></g></svg>
            )

        case "nocash":

            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#2e2209" d="M3 4.9v.05l.05-.01L4.11 6H3v12h13.11l4.73 4.73l1.27-1.27L4.57 3.91L2.39 1.73L1.11 3L3 4.9m3.41 4.51c.2-.19.35-.41.45-.66l2.3 2.3c-.11.3-.16.62-.16.95c0 .8.32 1.56.88 2.12S11.2 15 12 15c.33 0 .65-.05.95-.16L14.11 16H7c0-.53-.21-1.04-.59-1.41C6.04 14.21 5.53 14 5 14v-4c.53 0 1.04-.21 1.41-.59m11.28 5.09L21 17.8V6H9.2l2 2H17c0 .53.21 1.04.59 1.41c.37.38.88.59 1.41.59v4c-.5 0-.95.18-1.31.5Z"/></svg>
            )

        case "leaf":

            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#2e2209" d="M12 21q-1.6 0-3.025-.525T6.4 19.025L5.125 20.3q-.3.3-.7.288t-.7-.313q-.3-.3-.3-.712t.3-.713l1.25-1.25q-.925-1.15-1.45-2.587T3 12q0-3.75 2.638-6.375T12 3h7q.825 0 1.413.588T21 5v7q0 3.725-2.625 6.363T12 21Zm0-2q2.925 0 4.963-2.05T19 12V5h-7Q9.1 5 7.05 7.038T5 12q0 2.9 2.05 4.95T12 19Zm-1.325-2.125l5.175-4.625q.25-.225.15-.537t-.45-.363l-4.05-.4l2.425-3.325q.1-.125.088-.262T13.9 7.1q-.125-.125-.288-.125t-.287.125l-5.15 4.625q-.25.225-.15.538t.45.362l4.05.4l-2.45 3.325q-.075.125-.075.263t.125.262q.125.125.275.125t.275-.125ZM12 12Z"/></svg>
            )

        case "pylon": 

            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="#2e2209" d="M22.1 21.5L2.4 1.7L1.1 3l5 5h-.7l-1.3 2.5l1.8.9l.7-1.4h1.5l1 1l-2.9 11h2.1l.2-.9l3.5-5.2l3.5 5.2l.2.9h2.1l-.8-3.2l3.9 3.9l1.2-1.2M9.3 18.1l1.2-4.5l.9 1.3l-2.1 3.2m5.4 0L12.6 15l.2-.3l1.3 1.3l.6 2.1m-.5-7.1h.7l.2.9l-.9-.9m-.1-3h4.5l1.3 2.6l-1.8.9l-.7-1.5h-4.2l-3-3l.5-2h2.6l.8 3M8.4 5.2L6.9 3.7L7.8 2h8.5l1.3 2.5l-1.8.9L15 4H9l-.6 1.2Z"/></svg>
            )
    
        default:
            break;
    }
};